import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/style.scss";
import "./assets/css/responsive.scss";
import "react-toastify/dist/ReactToastify.css";
import "react-loading-skeleton/dist/skeleton.css";
import "react-datepicker/dist/react-datepicker.css";

import Store from "./components/context/Store";
import AuthRouters from "./utils/AuthRouters";

function App() {
    useEffect(() => {
        // Dynamically adding the Facebook Pixel script
        const fbPixelScript = document.createElement("script");
        fbPixelScript.async = true;
        fbPixelScript.src = "https://connect.facebook.net/en_US/fbevents.js";
        document.head.appendChild(fbPixelScript);

        // Initialize Facebook Pixel after script loads
        fbPixelScript.onload = () => {
            if (window.fbq) return;
            window.fbq = function () {
                window.fbq.callMethod
                    ? window.fbq.callMethod.apply(window.fbq, arguments)
                    : window.fbq.queue.push(arguments);
            };
            if (!window._fbq) window._fbq = window.fbq;
            window.fbq.push = window.fbq;
            window.fbq.loaded = true;
            window.fbq.version = "2.0";
            window.fbq.queue = [];
            window.fbq("init", "2311852975834940");
            window.fbq("track", "PageView");
        };

        // Adding the noscript pixel for browsers without JS
        const fbNoScript = document.createElement("noscript");
        fbNoScript.innerHTML = `
      <img height="1" width="1" style="display:none" 
      src="https://www.facebook.com/tr?id=2311852975834940&ev=PageView&noscript=1" />
    `;
        document.body.appendChild(fbNoScript);

        // Cleanup on component unmount
        return () => {
            document.head.removeChild(fbPixelScript);
            document.body.removeChild(fbNoScript);
        };
    }, []);

    return (
        <Store>
            <AuthRouters />
        </Store>
    );
}

export default App;
