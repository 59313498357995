import React, { Fragment, useContext, useEffect, useState } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import { matchRoutess, useLocation } from "react-router-dom";
import PromotionalPopup from "./general/modals/promotional-popup";
import BottomBanner from "./general/modals/bottom-banner";
import { Accounts } from "../axiosConfig";
import { Context } from "./context/Store";
import WorkshopModal from "./general/modals/workshop-modal";

const WebsiteLayout = (props) => {
    const [showBanner, setShowBanner] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [contestApplied, setContestApplied] = useState(false);
    const [campaignData, setCampaignData] = useState();
    const location = useLocation();
    const {
        dispatch,
        state: { user_data },
    } = useContext(Context);
    const accessToken = user_data.accessToken;

    const handleCheckEligibility = async () => {
        await Accounts.get(`api/v2/general/check-contest-eligibility`, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        })
            .then((response) => {
                console.log(
                    response,
                    "response of contest eligibility check from layout"
                );
                // if (response.status == 200) {
                //     setContestApplied(response.data.is_contest_applied)
                //     setShowBanner(response.data.is_eligible);
                //     if (response.data.is_eligible) {
                //         setTimeout(() => {
                //             setShowModal(!response.data.is_contest_applied);
                //         }, 2000);
                //     } else {
                //         setShowModal(response.data.is_eligible);
                //     }
                // }
                if (response.status === 200) {
                    const { is_contest_applied, is_eligible } = response.data;

                    setContestApplied(is_contest_applied);
                    setShowBanner(is_eligible);

                    if (showBanner || !showBanner) {
                        // Show the modal regardless of showBanner's value
                        setTimeout(() => {
                            setShowModal(true);
                        }, 2000);
                    }
                }
            })
            .catch((error) => {
                console.log(error);
                setShowModal(false);
                setShowBanner(false);
            });
    };
    const handleCampaign = async () => {
        await Accounts.get(`api/v2/general/check-campaign`, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        })
            .then((response) => {
                console.log(
                    response,
                    "response of contest eligibility check from layout"
                );
                setShowBanner(response.data.is_campaign_active);
                setCampaignData(response.data);
                if (accessToken) {
                    console.log(response, "normal");
                    handleCheckEligibility();
                } else if (response.status == 200) {
                    setTimeout(() => {
                        setShowModal(response.data.is_campaign_active);
                    }, 1000);
                }
            })
            .catch((error) => {
                console.log(error);
                setShowModal(false);
                setShowBanner(false);
            });
    };

    useEffect(() => {
        handleCampaign();
    }, [accessToken]);

    const handleCloseModal = () => {
        setShowModal(false);
    };

    return (
        <Fragment>
            {location.pathname != "/membership" &&
                location.pathname != "/join" && (
                    // <PromotionalPopup
                    //     isOpen={showModal}
                    //     onClose={handleCloseModal}
                    //     campaignData={campaignData}
                    //     setContestApplied={setContestApplied}
                    // />
                    <WorkshopModal
                        isOpen={showModal}
                        onClose={handleCloseModal}
                    />
                )}
            <Header
                admin={props.admin}
                logOutPopUp={props.logOutPopUp}
                setLogOutPopUp={props.setLogOutPopUp}
            />
            <main className={`website-container ${props.className}`}>
                {props.children}
            </main>
            <Footer />
            {/* {location.pathname != "/membership" &&
                location.pathname != "/join" && (
                    <BottomBanner
                        setShowModal={setShowModal}
                        showBanner={showBanner}
                        contestApplied={contestApplied}
                        campaignData={campaignData}
						setShowBanner={setShowBanner}
                    />
                )} */}
        </Fragment>
    );
};

export default WebsiteLayout;
